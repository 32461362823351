<template>
	<div>
		<OrderFormWeb
			:is-redirect-form="isRedirectForm"
			:form-id="formID"
			:is-update-order="updateOrder"
			@back="back"
			@submit="submit"
		/>
		<CustomModal
			v-if="adminLinkErr"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="closeModal"
		>
			<template #content>
				{{ messageErr }}
			</template>
		</CustomModal>
		<CustomModal
			v-if="customerLinkErr"
			:submit-text="$t('Button.Confirm')"
			@submit-modal="submitModal"
			@cancel-modal="closeModal"
		>
			<template #content>
				{{ messageErr }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { GET_FORM_WEB_DETAIL } from "../../forms/store/action-types"
import { CHECK_VALID_LINK, CREATE_ORDER_WEB } from "../store/action-types"
const { mapActions: formActions } = createNamespacedHelpers("forms")
const { mapActions } = createNamespacedHelpers("orders")
export default {
	name: "CreateOrderWeb",
	components: {
		OrderFormWeb: () => import("../components/OrderFormWeb.vue"),
	},
	props: {
		updateOrder: {
			type: Boolean,
			default: false,
		},
		formID: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			messageErr: null,
			adminLinkErr: false,
			customerLinkErr: false,
			dataCreateOrder: null,
		}
	},
	computed: {
		isRedirectForm() {
			return this.formID ? true : false
		},
	},
	created() {
		if (this.updateOrder) {
			this.$route.meta.label = this.$t("OrdersTitle.NewUpdate")
			this.$route.meta.title = this.$t("OrdersTitle.NewUpdate")
		}
		this.getFormWebDetail()
	},
	methods: {
		...formActions({ GET_FORM_WEB_DETAIL }),
		...mapActions({ CHECK_VALID_LINK, CREATE_ORDER_WEB }),
		async getFormWebDetail() {
			const params = {
				id: this.formID,
			}
			await this.GET_FORM_WEB_DETAIL(params)
		},
		back() {
			this.$router.go(-1)
		},
		async submit(params) {
			this.showLoading()
			this.dataCreateOrder = params
			const payload = {}

			if (
				!params.adminDropboxFolderLinks?.length &&
				!params.dropboxSharedFolderLinks?.length &&
				!params.driveSharedFolderLinks?.length
			)
				return await this.CREATE_ORDER_WEB(this.dataCreateOrder)

			if (params.adminDropboxFolderLinks?.length) {
				payload["adminDropboxFolderLinks"] = params.adminDropboxFolderLinks
			}
			if (params.dropboxSharedFolderLinks?.length) {
				payload["dropboxSharedFolderLinks"] = params.dropboxSharedFolderLinks
			}
			if (params.driveSharedFolderLinks?.length) {
				payload["driveSharedFolderLinks"] = params.driveSharedFolderLinks
			}
			const res = await this.checkLinkValid(payload)
			if (res && !res.ok) {
				const { code, message } = res.error
				this.messageErr = message
				if (code === "INVALID_CLOUD_LINKS_CUSTOMER") {
					this.customerLinkErr = true
				} else {
					this.adminLinkErr = true
				}
				this.$store.commit("set", ["modalShow", true])
			} else {
				params["fileUploadsUser"] = res.data.userUpload
				params["fileUploadsAdmin"] = res.data.adminUpload

				// if (params.adminDropboxFolderLinks?.length) {
				// 	delete params.adminDropboxFolderLinks
				// }
				// if (params.dropboxSharedFolderLinks?.length) {
				// 	delete params.dropboxSharedFolderLinks
				// }
				// if (params.driveSharedFolderLinks?.length) {
				// 	delete params.driveSharedFolderLinks
				// }

				await this.CREATE_ORDER_WEB(params)
			}
			this.hideLoading()
		},
		checkLinkValid(params) {
			return new Promise(resolve => {
				resolve(this.CHECK_VALID_LINK(params))
			})
		},
		closeModal() {
			this.customerLinkErr = false
			this.adminLinkErr = false
			this.$store.commit("set", ["modalShow", false])
		},
		async submitModal() {
			this.showLoading()
			this.closeModal()
			await this.CREATE_ORDER_WEB(this.dataCreateOrder)
			this.dataCreateOrder = null
			this.hideLoading()
		},
	},
}
</script>

<style></style>
